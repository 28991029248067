<template>
  <v-container fluid fill-height align-start>
    <v-row class="mx-10">
      <v-col cols="12" md="8" class="px-md-and-up-10">
        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="notification.title"
                :label="lang.title.label"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="notification.heading"
                :label="lang.heading.label"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-textarea
                v-model="notification.message"
                :label="lang.message.label"
                hide-details
                rows="2"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-chip-group
                v-model="notificationColor"
                center-active
                mandatory
                column
                active-class="white--text elevation-5 pa-6"
              >
                <v-chip v-for="color in colors" :key="color" :color="color">
                  {{ color }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" md="6">
              <v-chip-group
                v-model="notificationIcon"
                center-active
                mandatory
                column
                active-class="elevation-5 pa-6"
              >
                <v-chip v-for="icon in icons" :key="icon.name" :aria-label="`${icon.label} icon`">
                  <v-icon v-html="icon.name"> </v-icon>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="notification.imageUrl"
                :label="lang.imageUrl.label"
                hide-details
                prepend-icon="image"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="notification.link"
                :label="lang.link.label"
                :placeholder="lang.link.placeholder"
                prepend-icon="link"
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="datepickerMenu"
                :close-on-content-click="false"
                :nudge-left="40"
                transition="scale-transition"
                offset-x
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datePickerDateStr"
                    @keypress="return null"
                    :label="lang.ttl.label"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datePickerDateStr"
                  @input="datepickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="notification.userId"
                :label="lang.userId.label"
                prepend-icon="people"
                :placeholder="lang.userId.placeholder"
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="4">
        <h3>
          {{ lang.texts.notificationPreview }}
        </h3>
        <v-card style="zoom: 1.5">
          <v-card-text>
            <v-btn icon small style="z-index: 15" absolute right>
              <v-icon
                color="grey lighten-1"
                aria-label="Dismiss notification"
                @click.stop="
                  () => {
                    return false;
                  }
                "
              >
                cancel
              </v-icon>
            </v-btn>
            <v-list-item three-line link target="_blank" :href="notification.link">
              <v-list-item-content>
                <v-list-item-subtitle :class="`${notification.color}--text`">
                  <div>
                    <v-icon
                      small
                      v-text="notification.icon"
                      :color="notification.color"
                    ></v-icon>
                    {{ notification.title }}
                  </div>
                </v-list-item-subtitle>
                <v-list-item-title v-html="notification.heading"></v-list-item-title>
                <v-list-item-subtitle
                  class="text-wrap"
                  v-html="notification.message"
                ></v-list-item-subtitle>
                <span
                  class="caption grey--text"
                  v-html="getDateFromStamp(notification.createdAt)"
                ></span>
              </v-list-item-content>

              <v-list-item-action class="pa-0 ma-0 mt-2">
                <v-list-item-avatar v-if="notification.imageUrl" class="align-self-end">
                  <v-img :src="notification.imageUrl"></v-img>
                </v-list-item-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-footer app padless style="z-index: 10" class="shaza_dark_blue">
      <v-col class="text-right ma-0 pa-0" cols="12">
        <!-- <v-btn small dark class="elevation-0" text v-show="!!$routerHistory.previous().path" :to="{ path: $routerHistory.previous().path }">
          <v-icon>chevron_left</v-icon>
          {{buttonLang.back.label}}
        </v-btn> -->
        <v-btn small class="elevation-0" color="shaza_yellow" @click="publishIntent"
          >{{ lang.texts.publishNotification }}
          <v-icon>send_to_mobile</v-icon>
        </v-btn>
      </v-col>
    </v-footer>
  </v-container>
</template>

<script>
import { createShazacinUserNotifications } from "@/graphql/mutations";

const colors = ["blue", "green", "red", "orange", "pink", "cyan", "purple"];
const icons = [
  {name: "movie", label: "movie"},
  {name: "new_releases", label: "new releases"},
  {name: "featured_video", label: "featured video"},
  {name: "note", label: "note"},
  {name: "music_video", label: "music video"},
  {name: "radio", label: "radio"},
  {name: "subscriptions", label: "subscriptions"},
  {name: "web", label: "web window"},
  {name: "video_library", label: "video library"},
  {name: "chat", label: "chat bubble"},
  {name: "face", label: "expressionless face"},
  {name: "info", label: "information"},
  {name: "lightbulb", label: "lightbulb"},
  {name: "loyalty", label: "loyalty card"},
  {name: "theaters", label: "film strip"},
  {name: "verified", label: "checkmark"},
  {name: "add_alert", label: "alert bell"},
  {name: "tag_faces", label: "smiley face"},
];

const UserCommsLang = {
  texts: {
    notificationPreview: "In-app Preview:",
    publishNotification: "Publish Notification",
  },
  heading: {
    label: "Notification Heading",
  },
  title: {
    label: "Notification Title",
  },
  message: {
    label: "Notification Message",
  },
  icon: {
    label: "Notification Icon",
  },
  color: {
    label: "Notification Color",
  },
  imageUrl: {
    label: "URL of notification image",
  },
  ttl: {
    label: "Notification Expiry date (in YYYY-MM-DD format)",
  },
  userId: {
    label: "Recipient ID",
    placeholder:
      "ID of user to push this notification to. Type ALL to send to ALL subscribers.",
  },
  link: {
    label: "Notification Link",
    placeholder: "e.g. /title/50bbbb37-9662-4cda-af2f-57097edcc2dd or https://google.com",
  },
};

export default {
  name: "UserCommunication",
  data: () => ({
    colors,
    icons,
    datepickerMenu: false,
    datePickerDateStrValue: "",
    notification: {
      icon: "movie",
      color: "blue",
      createdAt: Date.now(),
      title: "Notification Title",
      heading: "Notification Heading",
      message: "Notification Message",
      imageUrl:
        "https://shazacin.com/wp-content/uploads/2020/08/cropped-NavyCyanWhiteText_rounded.png",
      link: "/title/titleId",
      TTL: Date.now() / 1000,
      adminId: "034db6f6-5317-49c7-b140-7bcb2b797a35",
      userId: "034db6f6-5317-49c7-b140-7bcb2b797a35",
    },
  }),
  computed: {
    lang() {
      return UserCommsLang;
    },
    notificationColor: {
      get() {
        const self = this;
        return self.colors.indexOf(self.notification.color);
      },
      set(v) {
        const self = this;
        this.notification.color = self.colors[v];
      },
    },
    notificationIcon: {
      get() {
        const self = this;
        return self.icons.findIndex(ic => ic.name == self.notification.icon)
        // return self.icons.indexOf(self.notification.icon);
      },
      set(v) {
        const self = this;
        console.info(`We have v here as: `, v)
        self.notification.icon = self.icons[v].name;
        self.notification.label = self.icons[v].label;
      },
    },
    datePickerDateStr: {
      get() {
        const self = this;
        return self.datePickerDateStrValue
      },
      set(v) {
        const self = this
        self.datePickerDateStrValue = v
        if (v && v.length && v.length == 10)
          this.notification.TTL = Math.trunc((new Date(v).getTime()) / 1000);
      },
    } 
  },
  methods: {
    publishIntent() {
      const self = this;
      self.confirm({
        heading: "Warning",
        msg: `You are about to publish this notification to <b>${
          self.notification.userId == "ALL"
            ? "EVERYONE"
            : "user ".concat(self.notification.userId)
        }</b>, <br>do you wish to continue?`,
        type: "warning",
        actions: [
          {
            color: "error",
            isFlat: true,
            text: "cancel",
          },
          {
            color: "shaza_yellow",
            isFlat: false,
            text: "Publish Now",
            method: self.publishNotification,
          },
        ],
      });
    },
    async publishNotification() {
      const self = this;
      // ! TODO: ensure userId is properly set.
      // TODO TODO TODO...
      console.error(`Starting publishNotification....`);
      self.alert({
        timeout: 5000,
        msg: self.$t("notifs.userCommunication.createShazacinUserNotifications.busy", {
          users:
            self.notification.userId == "ALL" ? "Everyone" : self.notification.userId,
        }),
      });

      let input = { ...self.notification };
      delete input.createdAt;
      delete input.label;
      input.adminId = self.userId;
      try {
        console.error(`Trying publishNotification....`);

        const result = await self.$Amplify.API.graphql(
          self.$Amplify.graphqlOperation(createShazacinUserNotifications, { input })
        );
        console.error(`After publishNotification.... result`, result);
        const notif = result.data.createShazacinUserNotifications;
        if (!!notif)
          self.alert({
            timeout: 5000,
            msg: self.$t(
              `notifs.userCommunication.createShazacinUserNotifications.success`,
              {
                users:
                  self.notification.userId == "ALL"
                    ? "Everyone"
                    : self.notification.userId,
              }
            ),
            color: "success",
            icon: "mdi-check-circle-outline",
          });
        else
          self.alert({
            timeout: 5000,
            msg: self.$t(
              `notifs.userCommunication.createShazacinUserNotifications.error`,
              { err: result?.data?.errors[0] || "none" }
            ),
            color: "error",
            icon: "alert-circle-outline",
          });
      } catch (e) {
        self.alert({
          timeout: 5000,
          msg: self.$t(`notifs.userCommunication.createShazacinUserNotifications.error`, {
            err: e.message,
          }),
          color: "error",
          icon: "alert-circle-outline",
        });
      }
    },
    hydrateUserId() {
      const self = this;
      self.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
        if (data && data.signInUserSession) {
          self.userId = data?.signInUserSession?.idToken?.payload?.sub || "unknown";
        }
      });
    },
    getDateFromStamp(timestamp) {
      return `1 minute ago`;
    },
  },
  mounted() {
    this.hydrateUserId();
  },
};
</script>

<style lang="scss" scoped></style>
